


















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import AddCircle from '@/app/ui/assets/add_circle.vue'
import locale from '../locale'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import controller, {
  ParamsExtraMultiplierInterface,
} from '@/app/ui/controllers/MembershipFormulaController'
import { DataObject } from '@/app/ui/components/DataTableV2/type'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import {
  EventBusConstants,
  LIST_EXTRAMULTIPLIER_PAGINATION,
  Utils,
} from '@/app/infrastructures/misc'
import { Dictionary } from 'vue-router/types/router'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'

export interface ExtraMultiplierInfoInterface {
  id: string | number
  extra_multiplier: string | number
}

export interface ParametersInterface {
  page: number | string
  perPage: number | string
}

@Component({
  name: 'LoyaltyProgramExtraMultiplier',
  components: {
    Button,
    AddCircle,
    DataTableV2,
    EmptyState,
    ModalAction,
    PaginationNav,
    LoadingOverlay,
  },
})
export default class LoyaltyProgramExtraMultiplier extends Vue {
  locale = locale
  controller = controller
  headers = [
    {
      title: locale.labe_no,
      customStyle: { minWidth: '77px', maxWidth: '77px' },
    },
    {
      title: locale.label_channel,
      customStyle: { minWidth: '160px', maxWidth: '100%' },
    },
    {
      title: locale.label_product_type,
      customStyle: { minWidth: '120px', maxWidth: '100%' },
    },
    {
      title: locale.label_multiplier,
      customStyle: { minWidth: '170px', maxWidth: '100%' },
    },
    {
      title: locale.label_action,
      customStyle: { minWidth: '124px', maxWidth: '124px' },
    },
  ]
  modalDelete = {
    id: NaN,
    visible: false,
  }
  parameters: ParametersInterface = {
    page: 1,
    perPage: LIST_EXTRAMULTIPLIER_PAGINATION,
  }

  created(): void {
    const queries = <ParametersInterface>(<unknown>this.$route.query)
    this.parameters = {
      page: Utils.alwaysNumber(String(queries.page)) || 1,
      perPage: LIST_EXTRAMULTIPLIER_PAGINATION,
    }

    this.fetchExtraMultiplierList()
  }

  private formatExtraMultiplierInfo(extraMultiplierInfo: string): string {
    const parse = extraMultiplierInfo ? JSON.parse(extraMultiplierInfo) : ''

    const result = parse
      ? parse?.map((item: ExtraMultiplierInfoInterface) => {
          return `[${item.extra_multiplier}]`
        })
      : ''
    return String(result).replace(/,/g, ' ')
  }

  get dataListExtraMultiplier(): Array<DataObject[]> {
    return this.controller?.dataExtraMultiplier.map((item, index) => {
      return [
        {
          value: index + 1,
          customStyle: { minWidth: '77px', maxWidth: '77px' },
        },
        {
          value: item.channel,
          customStyle: { minWidth: '160px', maxWidth: '100%' },
        },
        {
          value: item.productType,
          customStyle: { minWidth: '120px', maxWidth: '100%' },
        },
        {
          value: this.formatExtraMultiplierInfo(item.extraMultiplierInfo || ''),
          customStyle: { minWidth: '170px', maxWidth: '100%' },
        },
        {
          value: item.id,
          customStyle: { minWidth: '124px', maxWidth: '124px' },
        },
      ]
    })
  }

  private redirectCreateExtraMultiplier(): void {
    this.$router.push({ name: 'CreateLoyaltyProgramExtraMultiplierPage' })
  }

  private redirectToEdit(id: string | number): void {
    this.$router.push({
      name: 'EditLoyaltyProgramExtraMultiplierPage',
      params: { id: String(id) },
    })
  }

  private handleDelete(id: string | number): void {
    this.modalDelete = {
      id: Number(id),
      visible: true,
    }
  }

  private resetModalDelete(): void {
    this.modalDelete = {
      id: NaN,
      visible: false,
    }
  }

  private handleActionDelete(): void {
    controller.deleteExtraMultiplier(String(this.modalDelete.id))
  }

  get totalItemPagination(): number {
    return controller.paginationData.totalItem || 0
  }

  get params(): ParamsExtraMultiplierInterface {
    return {
      ...this.parameters,
    }
  }

  @Watch('params')
  onParamsChanged(
    val: Dictionary<string | (string | null)[] | null | undefined> | undefined
  ): void {
    this.$router
      .replace({
        query: val,
      })
      .catch(() => false)
  }

  private fetchExtraMultiplierList(): void {
    controller.getListExtraMultiplier(this.params)
  }

  @Watch('controller.statusDeleteExtraMultiplier')
  onStatusDeleteExtraMultiplierChanged(status: string): void {
    if (
      status !== '' &&
      status === EventBusConstants.DELETE_EXTRA_MULTIPLIER_SUCCESS
    ) {
      this.resetModalDelete()
      this.$notify({
        title: locale.label_delete_success,
        text: locale.label_extra_multiplier_deleted,
        type: 'success',
        duration: 5000,
      })
      this.fetchExtraMultiplierList()
    }
    controller.setStatusDeleteExtraMultiplier('')
  }

  beforeDestroy(): void {
    controller.setStatusDeleteExtraMultiplier('')
  }
}
